import Splide from '@splidejs/splide';

const elem = document.querySelector('.splide');
const perPage = parseInt(elem.dataset.carouselPerPage, 10);
const alwaysShowOne = elem.dataset.carouselAlwaysShowOne === 'true';
const breakPoint = parseInt(elem.dataset.carouselBreakPoint, 10);

const breakpoints = {
  1036: {
    perPage: alwaysShowOne ? 1 : 2,
    perMove: alwaysShowOne ? 1 : 2,
  },
};

breakpoints[breakPoint] = {
  perPage: 1,
  perMove: 1,
};

const splide = new Splide(elem, {
  perPage,
  perMove: 1,
  classes: {
    prev: 'splide__arrow--prev jo-prev-arrow',
    next: 'splide__arrow--next jo-next-arrow',
    pagination: 'splide__pagination jo-pagination',
    page: 'splide__pagination__page jo-pagination-page',
  },
  breakpoints,
});

splide.mount();
